import axios from "axios";

const resolveOriginalRequest = (originalRequest) => {
  axios(originalRequest).then((res) => {
    res.config.url = res.config.url.split("?")[0];

    switch (res.config.url) {
      case "/devices/meters":
        if (res.config.method === "get") {
          if (res.status === 200 && res.data.response === "200") {
            res.config.saveMeters(res.data.dataArray);
            var currentMeterId = null;
            var currentMeterData = null;

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const param = urlParams.get("deviceId");

            if (!isNaN(param) && param && param.length !== 0) {
              res.data.dataArray.forEach((meter) => {
                meter.kh = parseFloat(meter.kh); //convert kh to number

                if (meter.default) {
                  res.config.saveDefaultMeter(meter);
                }
                if (meter.id === parseInt(param)) {
                  currentMeterId = meter.id;
                  currentMeterData = meter;
                }
              });
            } else {
              res.data.dataArray.forEach((meter) => {
                if (meter.default) {
                  res.config.saveDefaultMeter(meter);
                  currentMeterId = meter.id;
                  currentMeterData = meter;
                } else if (!meter.default && currentMeterId === null) {
                  res.config.saveDefaultMeter(meter);
                  currentMeterId = meter.id;
                  currentMeterData = meter;
                }
              });
            }
            res.config.saveCurrentMeter(currentMeterId);
            res.config.saveCurrentMeterData(currentMeterData);
          }
        } else {
          res.config.meterSettingsSavedSuccessfully(true);
        }
        break;
      case "/widgets/rightnow":
        if (res.config.method === "get") {
          if (res.status === 200 && res.data.response === "200") {
            res.config.saveRightNow(res.data.dataArray);
          }
          //res.config.saveRightNowCurrentUnit(res.data.dataArray.settings.unit);
        }
        break;
      case "/widgets/appliances/ranges":
        if (res.config.method === "get") {
          if (res.status === 200 && res.data.response === "200") {
            res.config.saveAppliancesRanges(res.data.dataArray);
          }
        }
        break;
      case "/widgets/appliances":
        if (res.config.method === "get") {
          if (res.request.responseURL.split("appliance_id")[1] === undefined) {
            if (res.status === 200 && res.data.response === "200") {
              res.config.saveAppliances(res.data.dataArray);
              if (res.data.dataArray.settings) {
                res.config.saveAppliancesCurrentUnit(
                  res.data.dataArray.settings.unit
                    ? res.data.dataArray.settings.unit
                    : "k"
                );
              }
            }
          } else {
            if (res.status === 200 && res.data.response === "200") {
              res.config.saveAppliancesDrilldown(res.data.dataArray.data);
            }
          }
        } else {
          if (res.status === 200 && res.data.response === "200") {
            res.config.appliancesSettingsSavedSuccessfully(true);
          }
        }
        break;
      case "/widgets/realtime":
        if (res.config.method === "get") {
          if (res.status === 200 && res.data.response === "200") {
            res.config.saveRealtime(res.data.dataArray);
            res.config.saveLoadingRealtime(false);
          }
        }
        break;
      case "/widgets/gasgauge":
        if (res.config.method === "get") {
          if (res.status === 200 && res.data.response === "200") {
            res.config.saveBudget(res.data.dataArray);
          }
        } else {
          if (res.status === 200 && res.data.response === "200") {
            res.config.saveBudgetSettingsSuccessfully(true);
          }
        }
        break;
      case "/widgets/comparison":
        if (res.config.method === "get") {
          if (res.status === 200 && res.data.response === "200") {
            res.config.saveComparison(res.data.dataArray);
            res.config.saveComparisonCurrentUnit(
              res.data.dataArray.settings.unit
            );
          }
        }
        break;
      case "/widgets/history":
        if (res.config.method === "get") {
          if (res.status === 200 && res.data.response === "200") {
            res.config.saveHistory(res.data.dataArray);
            res.config.saveHistoryCurrentUnit(res.data.dataArray.settings.unit);
            res.config.saveHistoryCurrentType(res.data.dataArray.settings.type);
            res.config.saveLoadingHistory(false);
          }
        }
        break;
      case "/signup":
        if (res.status === 200 && res.data.response === "200") {
          //
        }
        break;
      case "/countries":
        if (res.status === 200 && res.data.response === "200") {
          res.config.saveCountries(res.data.dataArray);
        }
        break;
      case "/administrative_areas":
        if (res.status === 200 && res.data.response === "200") {
          res.config.saveAdministrativeAreas(res.data.dataArray);
        }
        break;
      case "/users/profile":
        if (res.config.method === "get") {
          if (res.status === 200 && res.data.response === "200") {
            res.config.saveUser(res.data.dataArray);
          }
        } else if (res.config.method === "delete") {
          if (res.status === 200 && res.data.response === "200") {
            res.config.saveProfileDeleted(true);
          }
        } else {
          if (res.status === 200 && res.data.response === "200") {
            res.config.profileSettingsSavedSuccessfully(true);
          }
        }
        break;
      case "/users/language":
        break;
      case "/devices/alerts":
        if (res.status === 200 && res.data.response === "200") {
          res.config.saveAlerts(res.data.dataArray);
        }
        break;
      case "/devices/control/update_firmware":
        if (res.status === 200 && res.data.response === "200") {
          res.config.updateFirmwareSent({
            status: "pending",
            deviceId: res.config.currentMeter,
          });
        }
        break;
      case "/devices/control/delete":
        if (res.status === 200 && res.data.response === "200") {
          res.config.saveDeviceDeleted(true);
          window.location.href = "/dashboard";
        }
        break;
      case "/devices/control/upload_image":
        let uploadObj = {
          location: res.data.dataArray.Location,
        };

        if (res.config.hasMeterImage) {
          document.getElementById("meterImage").src = "";
          document.getElementById("meterImage").src = uploadObj.location;
        }

        if (res.status === 200 && res.data.response === "200") {
          res.config.saveHasMeterImage(true);
          res.config.meterImageUploadedSuccessfully(true);
        }
        break;
      case "/users/token":
        res.config.saveClientToken(res.data.dataArray.client_token);
        break;
      case "/installation/cloudConnector":
        if (res.data.dataArray[0] !== undefined) {
          if (res.data.dataArray.length === 1) {
            res.config.saveCC(res.data.dataArray[0]);
          } else {
            res.config.saveMoreThanOneCCList(res.data.dataArray);
          }
        }
        break;
      case "/installation/sensor":
        if (res.data.dataArray[0] !== undefined) {
          res.config.saveSensor(res.data.dataArray[0]);
        }
        break;
      case "/installation/claimDevice":
        break;
      case "/migrate":
        if (res.data.response === "200") {
          res.config.validMigration(true);

          let type = "ethernet";

          if (
            res.config.deviceForMigration.wifi_rssi !== "" ||
            res.config.deviceForMigration.wifi_rssi !== null ||
            res.config.deviceForMigration.wifi_rssi !== undefined ||
            res.config.deviceForMigration.wifi_rssi !== "0"
          ) {
            type = "wifi";
          }

          res.config.saveInstallationType(type);
        }

        break;
      default:
        return null;
    }
  });
};

export default resolveOriginalRequest;
