import React, { useState, Suspense } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Icon, Dialog, MenuItem, Button, Position } from "@blueprintjs/core";
import FlagEn from "../../assets/flags/gb.svg";
import FlagFr from "../../assets/flags/fr.svg";
import FlagEs from "../../assets/flags/es.svg";
import FlagBg from "../../assets/flags/bg.svg";
import DefaultLogo from "../../assets/ui/logo-dashboard.svg";
import SmallWhiteLogo from "../../assets/ui/logo-white.svg";

import "../styles/App.css";
import styles from "../styles";
import { useTranslation } from "react-i18next";
import useReactSimpleMatchMedia from "react-simple-matchmedia";
import {
  getMeters,
  saveProfileLanguage,
  replaceDevice,
} from "../../ecApi/ecApi";
import { getLanguage } from "../../common/GetLanguageData";
import instance from "../../apis/energycloud";
import { Select } from "@blueprintjs/select";

import {
  saveInstallationType,
  saveMeterChoice,
  saveRedirectWizard,
  signIn,
  signOut,
  saveUser,
  saveMeters,
  saveDefaultMeter,
  saveCurrentMeter,
  saveCurrentMeterData,
  cleanMeters,
  cleanCurrentMeter,
  cleanRightNow,
  cleanAppliances,
  cleanRealtime,
  cleanBudget,
  cleanComparison,
  cleanHistory,
  cleanAlerts,
  saveRightNow,
  saveRightNowCurrentUnit,
  saveAppliances,
  saveAppliancesCurrentUnit,
  saveRealtime,
  saveRealtimeCurrentUnit,
  saveBudget,
  saveComparisonCurrentUnit,
  saveComparison,
  saveHistory,
  saveHistoryCurrentUnit,
  saveHistoryCurrentType,
  setServerError,
  saveHelp,
  saveAbout,
  saveTerms,
  saveTips,
  saveNotifications,
  savePrivacy,
  saveExplain,
  saveAlerts,
  saveLoadingRealtime,
  saveLoadingHistory,
  profileSettingsSavedSuccessfully,
  saveCC,
} from "../../actions";

let Navigation = (props) => {
  const { t, i18n } = useTranslation();
  const iconSize = 20;
  const isMobileDevice = useReactSimpleMatchMedia(
    "(min-width: 0px) and (max-width: 991px)"
  );

  const [openMenu, setOpenMenu] = useState(false);
  const [openSensorModal, setOpenSensorModal] = useState(false);
  const [openReplaceModal, setOpenReplaceModal] = useState(false);
  const [openSensorConfirmModal, setOpenSensorConfirmModal] = useState(false);
  const [commandSent, setCommandSent] = useState(false);
  const [replaceMeterSelected, setReplaceMeterSelected] = useState(null);
  const [replaceMeterWithSelected, setReplaceMeterWithSelected] =
    useState(null);
  const [replaceMeterSelectedId, setReplaceMeterSelectedId] = useState(null);
  const [replaceMeterWithSelectedId, setReplaceMeterWithSelectedId] =
    useState(null);

  const itemRenderer = (item, { handleClick }) => {
    return (
      <MenuItem
        key={item.id}
        text={item.name}
        onClick={handleClick}
        shouldDismissPopover={true}
        className="selectDropDown"
        id={item.name.replace(" ", "")}
      />
    );
  };

  const itemRenderer2 = (item, { handleClick }) => {
    return (
      <MenuItem
        key={item.id}
        text={item.name}
        onClick={handleClick}
        shouldDismissPopover={true}
        className="selectDropDown"
        id={item.name.replace(" ", "")}
        disabled={item.name === replaceMeterSelected ? true : false}
      />
    );
  };

  const handleReplaceDevice = () => {
    let data = {
      oldId: replaceMeterSelectedId,
      newId: replaceMeterWithSelectedId,
    };
    replaceDevice(props, data);

    //clear what you have selected
    setReplaceMeterSelected(null);
    setReplaceMeterSelectedId(null);
    setReplaceMeterWithSelected(null);
    setReplaceMeterWithSelectedId(null);
  };

  const handleReplaceDeviceSelect = (item) => {
    setReplaceMeterSelected(item.name);
    setReplaceMeterSelectedId(item.id);
  };

  const handleReplaceDeviceSelectWith = (item) => {
    setReplaceMeterWithSelected(item.name);
    setReplaceMeterWithSelectedId(item.id);
  };

  const setSensor = async (deviceId) => {
    await instance
      .get("/set_sensor", {
        params: {
          address: 0,
          did: deviceId,
        },
      })
      .then(() => {
        if (!commandSent) {
          setCommandSent(true);
        }
      })
      .catch((error) => {});
  };

  if (!isMobileDevice && openMenu) {
    setOpenMenu(false);
  }

  const handleSetupSensor = () => {
    setOpenSensorModal(true);
  };

  const handleReplaceModal = () => {
    setOpenReplaceModal(true);
  };

  const hideSubMenu = () => {
    const navElement = document.getElementById("submenu");
    if (navElement) {
      navElement.classList.add("hideSubmenu");
    }
  };

  const showSubMenu = () => {
    const navElement = document.getElementById("submenu");
    if (navElement) {
      navElement.classList.remove("hideSubmenu");
    }
  };

  const showLanguagesSubMenu = () => {
    const navElement = document.getElementById("languagesSubmenu");
    if (navElement) {
      navElement.classList.remove("hideSubmenu");
    }
  };

  const setLanguage = async (lang) => {
    await localStorage.removeItem("lang");
    await localStorage.setItem("lang", lang);
    await i18n.changeLanguage(lang);
    await getLanguage(props);

    let data = {
      language: lang,
    };

    data = JSON.stringify(data);
    saveProfileLanguage(props, data);
  };

  const reshapeContent = (menuOpened) => {
    if (menuOpened === true) {
      const pageTitleElement = document.getElementById("pageTitle");
      if (pageTitleElement) {
        pageTitleElement.classList.add("extendPageTitle");
      }
      const containerElement = document.getElementById("container");
      if (containerElement) {
        containerElement.classList.add("extendedContent");
      }
      const weatherElement = document.getElementById("weatherBox");
      if (weatherElement) {
        weatherElement.classList.add("extendedContent");
      }
      const mainContentElement = document.getElementById("mainContent");
      if (mainContentElement) {
        mainContentElement.classList.add("extendedContent");
      }
    } else {
      const pageTitleElement = document.getElementById("pageTitle");
      if (pageTitleElement) {
        pageTitleElement.classList.remove("extendPageTitle");
      }
      const containerElement = document.getElementById("container");
      if (containerElement) {
        containerElement.classList.remove("extendedContent");
      }
      const weatherElement = document.getElementById("weatherBox");
      if (weatherElement) {
        weatherElement.classList.remove("extendedContent");
      }
      const mainContentElement = document.getElementById("mainContent");
      if (mainContentElement) {
        mainContentElement.classList.remove("extendedContent");
      }
    }
  };

  const pathName = window.location.pathname;

  return (
    <>
      <Suspense
        fallback={
          <div>
            {props.isSignedIn ? (
              <div style={styles.rightBorder}>
                <div
                  id="navigation"
                  style={
                    isMobileDevice && !openMenu
                      ? styles.navigationHidden
                      : isMobileDevice && openMenu
                      ? styles.navigationMobile
                      : styles.navigation
                  }
                ></div>
              </div>
            ) : null}
          </div>
        }
      >
        {props.isSignedIn ? (
          <div style={styles.rightBorder}>
            {openSensorModal ? (
              <Dialog
                icon="help"
                onClose={() => {
                  setOpenSensorModal(false);
                }}
                isOpen
                title={t("wizard.whatWouldYouDo")}
                style={styles.wizardDialog}
              >
                <div style={styles.wizardDialogContent}>
                  <div>
                    <br />
                    <button
                      className="bp3-button bp3-intent-success bp3-large"
                      style={styles.wizardStartButton}
                      onClick={() => {
                        setOpenSensorModal(false);
                        setOpenSensorConfirmModal(true);
                        props.saveCC(props.currentMeterData);
                      }}
                    >
                      {t("wizard.newSensorOrResetup")}
                    </button>
                    <br />
                    <br />
                    <Link
                      to="/setup"
                      className="bp3-button bp3-intent-success bp3-large"
                      style={styles.wizardStartButton}
                      onClick={() => {
                        setOpenSensorModal(false);
                        props.saveMeterChoice(undefined);
                        props.saveCC(props.currentMeterData);

                        if (
                          props.currentMeterData.wifi.rssi !== 0 ||
                          props.currentMeterData.wifi.rssi < 0
                        ) {
                          props.saveInstallationType("wifi");
                          props.saveRedirectWizard("final");
                          setSensor(props.currentMeter);
                        } else {
                          props.saveInstallationType("ethernet");
                          props.saveRedirectWizard("final");
                          setSensor(props.currentMeter);
                        }
                      }}
                    >
                      {t("wizard.existingSensorIsSetup")}
                    </Link>
                  </div>
                </div>
              </Dialog>
            ) : null}

            {openSensorConfirmModal ? (
              <Dialog
                icon="info-sign"
                onClose={() => {
                  setOpenSensorConfirmModal(false);
                }}
                isOpen
                title={t("wizard.pleaseConfirm")}
                style={styles.wizardDialog}
              >
                <div style={styles.wizardDialogContent}>
                  <div>
                    {t("wizard.thisActionWillDisconnect")}
                    <br />
                    <br />
                    <Link
                      to="/setup"
                      className="bp3-button bp3-intent-success bp3-large"
                      style={styles.wizardStartButton}
                      onClick={() => {
                        setOpenSensorConfirmModal(false);
                        props.saveMeterChoice(undefined);

                        if (
                          props.currentMeterData.wifi.rssi !== 0 ||
                          props.currentMeterData.wifi.rssi < 0
                        ) {
                          props.saveInstallationType("wifi");
                          props.saveRedirectWizard("choose_sensor");
                          setSensor(props.currentMeter);
                        } else {
                          props.saveInstallationType("ethernet");
                          props.saveRedirectWizard("choose_sensor");
                          setSensor(props.currentMeter);
                        }
                      }}
                    >
                      {t("wizard.confirm")}
                    </Link>
                    <br />
                    <br />
                    <button
                      className="bp3-button bp3-intent-none bp3-large"
                      style={styles.wizardStartButton}
                      onClick={() => {
                        setOpenSensorConfirmModal(false);
                      }}
                    >
                      {t("wizard.cancel")}
                    </button>
                  </div>
                </div>
              </Dialog>
            ) : null}

            {openReplaceModal ? (
              <Dialog
                icon="exchange"
                onClose={() => {
                  setOpenReplaceModal(false);
                  //clear what you have selected
                  setReplaceMeterSelected(null);
                  setReplaceMeterSelectedId(null);
                  setReplaceMeterWithSelected(null);
                  setReplaceMeterWithSelectedId(null);
                }}
                isOpen
                title={t("navigation.replaceDevice")}
                style={styles.wizardDialog}
              >
                <div style={styles.wizardDialogContent}>
                  <div>
                    {t("replaceDevice.explain")}
                    <br />
                    <br />
                    <div className="bp3-select">
                      <Select
                        items={props.meters}
                        filterable={false}
                        onItemSelect={handleReplaceDeviceSelect}
                        itemRenderer={itemRenderer}
                        popoverProps={{
                          position: Position.BOTTOM,
                        }}
                        className="selectDropDown"
                      >
                        <Button
                          className="selectMenu"
                          text={
                            replaceMeterSelected === null
                              ? t("replaceDevice.selectDevice")
                              : replaceMeterSelected
                          }
                        />
                      </Select>
                    </div>
                    {replaceMeterSelected ? (
                      <div>
                        <br />
                        {t("replaceDevice.explain2")}
                        <br />
                        <br />
                        <div className="bp3-select">
                          <Select
                            items={props.meters}
                            filterable={false}
                            onItemSelect={handleReplaceDeviceSelectWith}
                            itemRenderer={itemRenderer2}
                            popoverProps={{
                              position: Position.BOTTOM,
                            }}
                            className="selectDropDown"
                          >
                            <Button
                              className="selectMenu"
                              text={
                                replaceMeterWithSelected === null
                                  ? t("replaceDevice.selectDevice")
                                  : replaceMeterWithSelected
                              }
                            />
                          </Select>
                        </div>
                      </div>
                    ) : null}

                    {replaceMeterWithSelected ? (
                      <div style={styles.wizardNote}>
                        <strong>{t("wizard.note")}</strong>
                        {t("replaceDevice.warning")}{" "}
                        <strong>{replaceMeterSelected}</strong>{" "}
                        {t("replaceDevice.warning2")}{" "}
                        <strong>{replaceMeterWithSelected}</strong>.{" "}
                        {t("replaceDevice.warning3")}
                      </div>
                    ) : (
                      <div>
                        <br />
                        <br />
                      </div>
                    )}
                    <button
                      className="bp3-button bp3-intent-success bp3-large"
                      style={styles.wizardStartButton}
                      onClick={() => {
                        setOpenReplaceModal(false);
                        handleReplaceDevice();
                      }}
                      disabled={replaceMeterWithSelected === null}
                    >
                      {t("navigation.replaceDevice")}
                    </button>
                  </div>
                </div>
              </Dialog>
            ) : null}

            {pathName === "/dashboard" ||
            pathName === "/settings" ||
            pathName === "/profile" ||
            pathName === "/messages" ||
            pathName === "/help" ||
            pathName === "/setup" ? (
              <div id="mobileNavigation">
                {/* HAMBURGER MENU */}
                {isMobileDevice ? (
                  <div
                    style={!openMenu ? styles.hamburger : styles.closeMenu}
                    onClick={() => {
                      document.body.scrollTop =
                        document.documentElement.scrollTop = 0;
                      reshapeContent(!openMenu);
                      setOpenMenu(!openMenu);
                    }}
                  >
                    {!openMenu ? (
                      <img
                        src={SmallWhiteLogo}
                        alt="EnergyCloud"
                        style={styles.smallWhiteLogo}
                      />
                    ) : (
                      <Icon icon="cross" iconSize={18} color="white" />
                    )}
                  </div>
                ) : null}
              </div>
            ) : null}
            {/* BACKDROP */}
            <div
              style={openMenu ? styles.backDrop : styles.noBackDrop}
              onClick={() => {
                reshapeContent(!openMenu);
                setOpenMenu(!openMenu);
                document.documentElement.scrollTop = 0;
              }}
              className="backDrop"
              id="backDrop"
            ></div>

            {/* NAVIGATION SIDEBAR */}
            {pathName === "/dashboard" ||
            pathName === "/settings" ||
            pathName === "/profile" ||
            pathName === "/messages" ||
            pathName === "/help" ||
            pathName === "/setup" ? (
              <div
                id="navigation"
                className="fadeIn"
                style={
                  isMobileDevice && !openMenu
                    ? styles.navigationHidden
                    : isMobileDevice && openMenu
                    ? styles.navigationMobile
                    : styles.navigation
                }
              >
                <div style={styles.navigationLogo}>
                  <img
                    src={DefaultLogo}
                    alt="EnergyCloud"
                    style={styles.navigationLogoImage}
                  />
                </div>
                <div style={styles.menu}>
                  <ul style={styles.navigationList}>
                    <li
                      style={styles.navigationElement}
                      className="navLinkWithSubmenu"
                    >
                      <span
                        style={styles.linkStyle}
                        className={
                          pathName === "/dashboard"
                            ? "navLink active"
                            : "navLink"
                        }
                        onMouseOver={() => {
                          showSubMenu();
                        }}
                        disabled={true}
                      >
                        <Icon icon="home" iconSize={iconSize} />
                        <div style={styles.linkText}>
                          {t("navigation.dashboard")}
                        </div>
                      </span>

                      <ul
                        style={styles.subMenu}
                        id="submenu"
                        className="submenu"
                      >
                        <div
                          className={
                            props.meters && props.meters.length > 0
                              ? "devicesList"
                              : ""
                          }
                        >
                          {props.meters && props.meters.length > 0
                            ? props.meters.map((obj, idx) => {
                                return (
                                  <div key={idx}>
                                    <Link
                                      disabled={
                                        props.currentMeter ? false : true
                                      }
                                      to={`/dashboard?deviceId=${obj.id}`}
                                      style={styles.subLinkStyle}
                                      className={
                                        props.currentMeter === obj.id
                                          ? "subNavLink active"
                                          : "subNavLink"
                                      }
                                      onClick={() => {
                                        document.body.scrollTop =
                                          document.documentElement.scrollTop = 0;
                                        setOpenMenu(!openMenu);
                                        hideSubMenu();
                                        props.cleanCurrentMeter();

                                        if (pathName === "/dashboard") {
                                          //Clean meters and data
                                          props.cleanMeters();
                                          props.cleanRightNow();
                                          props.cleanAppliances();
                                          props.cleanRealtime();
                                          props.cleanBudget();
                                          props.cleanComparison();
                                          props.cleanHistory();
                                          props.cleanAlerts();
                                          getMeters(props);
                                          props.saveCurrentMeterData(obj);
                                          props.saveCurrentMeter(obj.id);
                                        }
                                      }}
                                    >
                                      {obj.name}
                                    </Link>
                                  </div>
                                );
                              })
                            : null}
                        </div>
                        <Link
                          disabled={props.currentMeter ? false : true}
                          to={`/setup`}
                          style={
                            props.meters && props.meters.length > 0
                              ? styles.setupSubLinkStyle
                              : styles.setupSubLinkStyleNoDevice
                          }
                          className="setupSubNavLink"
                          onClick={() => {
                            props.saveInstallationType(undefined);
                            props.saveMeterChoice(undefined);
                            setOpenMenu(!openMenu);
                          }}
                        >
                          <span style={styles.setupNav}>
                            {t("navigation.setup")}
                          </span>
                        </Link>
                        {props.meters && props.meters.length > 0 ? (
                          <div
                            style={styles.setupSubLinkStyle}
                            className="setupSubNavLink"
                            onClick={() => {
                              handleSetupSensor();
                              setOpenMenu(!openMenu);
                            }}
                          >
                            <span style={styles.setupNav}>
                              {t("navigation.setupSensor")}
                            </span>
                          </div>
                        ) : null}
                        {props.meters && props.meters.length > 1 ? (
                          <div
                            style={styles.setupSubLinkStyle}
                            className="setupSubNavLink"
                            onClick={() => {
                              handleReplaceModal();
                              setOpenMenu(!openMenu);
                            }}
                          >
                            <span style={styles.setupNav}>
                              {t("navigation.replaceDevice")}
                            </span>
                          </div>
                        ) : null}
                      </ul>
                    </li>
                    <li style={styles.navigationElement}>
                      <Link
                        disabled={props.currentMeter ? false : true}
                        to={
                          props.currentMeter
                            ? `/settings?deviceId=${props.currentMeter}`
                            : `/settings`
                        }
                        style={styles.linkStyle}
                        className={
                          pathName === "/settings" && props.meters
                            ? "navLink active"
                            : pathName !== "/settings" && props.meters
                            ? "navLink"
                            : "navLink disabled"
                        }
                        onClick={() => {
                          setOpenMenu(!openMenu);
                        }}
                      >
                        <Icon icon="cog" iconSize={iconSize} />
                        <div style={styles.linkText}>
                          {t("navigation.settings")}
                        </div>
                      </Link>
                    </li>
                    <li style={styles.navigationElement}>
                      <Link
                        disabled={props.currentMeter ? false : true}
                        to={
                          props.currentMeter
                            ? `/profile?deviceId=${props.currentMeter}`
                            : `/profile`
                        }
                        style={styles.linkStyle}
                        className={
                          pathName === "/profile" ? "navLink active" : "navLink"
                        }
                        onClick={() => {
                          setOpenMenu(!openMenu);
                        }}
                      >
                        <Icon icon="user" iconSize={iconSize} />
                        <div style={styles.linkText}>
                          {t("navigation.profile")}
                        </div>
                      </Link>
                    </li>
                    <li style={styles.navigationElementAlerts}>
                      {props.alerts &&
                      props.alerts.alerts &&
                      props.alerts.alerts.length > 0 ? (
                        <div style={styles.alertsCount}>
                          {props.alerts.alerts.length}
                        </div>
                      ) : null}
                      <Link
                        disabled={props.currentMeter ? false : true}
                        to={
                          props.currentMeter
                            ? `/messages?deviceId=${props.currentMeter}`
                            : `/messages`
                        }
                        style={styles.linkStyle}
                        className={
                          pathName === "/messages"
                            ? "navLink active"
                            : "navLink"
                        }
                        onClick={() => {
                          setOpenMenu(!openMenu);
                        }}
                      >
                        <Icon icon="envelope" iconSize={iconSize} />
                        <div style={styles.linkText}>
                          {t("navigation.messages")}
                        </div>
                      </Link>
                    </li>
                    <li style={styles.navigationElement}>
                      <Link
                        disabled={props.currentMeter ? false : true}
                        to={
                          props.currentMeter
                            ? `/help?deviceId=${props.currentMeter}`
                            : `/help`
                        }
                        style={styles.linkStyle}
                        className={
                          pathName === "/help" ? "navLink active" : "navLink"
                        }
                        onClick={() => {
                          setOpenMenu(!openMenu);
                        }}
                      >
                        <Icon icon="help" iconSize={iconSize} />
                        <div style={styles.linkText}>
                          {t("navigation.help")}
                        </div>
                      </Link>
                    </li>
                    <li
                      style={styles.navigationElement}
                      className="navLinkWithSubmenu"
                    >
                      <span
                        style={styles.linkStyle}
                        className="navLink"
                        onMouseOver={() => {
                          showLanguagesSubMenu();
                        }}
                      >
                        <Icon icon="globe" iconSize={iconSize} />
                        <div style={styles.linkText}>
                          {t("navigation.language")}
                        </div>
                      </span>
                      <ul
                        style={styles.languagesSubMenu}
                        className="languagesSubmenu"
                      >
                        <div className="languagesList">
                          <li>
                            <div>
                              <div
                                style={styles.subLinkStyle}
                                className={"subNavLink"}
                                onClick={() => {
                                  setLanguage("en");
                                }}
                              >
                                <div style={styles.flag}>
                                  <img
                                    src={FlagEn}
                                    alt=""
                                    style={styles.flagImage}
                                  />
                                </div>
                                <div>
                                  <span className="pl-5">English</span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div>
                              <div
                                style={styles.subLinkStyle}
                                className={"subNavLink"}
                                onClick={() => {
                                  setLanguage("fr");
                                }}
                              >
                                <div style={styles.flag}>
                                  <img
                                    src={FlagFr}
                                    alt=""
                                    style={styles.flagImage}
                                  />
                                </div>
                                <div>
                                  <span className="pl-5">Français</span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div>
                              <div
                                style={styles.subLinkStyle}
                                className={"subNavLink"}
                                onClick={() => {
                                  setLanguage("es");
                                }}
                              >
                                <div style={styles.flag}>
                                  <img
                                    src={FlagEs}
                                    alt=""
                                    style={styles.flagImage}
                                  />
                                </div>
                                <div>
                                  <span className="pl-5">Español</span>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li>
                            <div>
                              <div
                                style={styles.subLinkStyle}
                                className={"subNavLink"}
                                onClick={() => {
                                  setLanguage("bg");
                                }}
                              >
                                <div style={styles.flag}>
                                  <img
                                    src={FlagBg}
                                    alt=""
                                    style={styles.flagImage}
                                  />
                                </div>
                                <div>
                                  <span className="pl-5">Български</span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                      </ul>
                    </li>
                    <li style={styles.navigationElement}>
                      <Link
                        disabled={props.currentMeter ? false : true}
                        to="/logout"
                        style={styles.linkStyle}
                        className={
                          props.currentPage === "logout"
                            ? "navLink active"
                            : "navLink"
                        }
                        onClick={() => {
                          props.cleanMeters();
                          props.cleanRightNow();
                          props.cleanAppliances();
                          props.cleanRealtime();
                          props.cleanBudget();
                          props.cleanComparison();
                          props.cleanHistory();
                          props.cleanAlerts();
                          setOpenMenu(!openMenu);
                        }}
                      >
                        <Icon icon="log-out" iconSize={iconSize} />
                        <div style={styles.linkText}>
                          {t("navigation.signOut")}
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <></>
        )}
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    meterChoice: state.wizard.meterChoice,
    installationType: state.wizard.installationType,
    alerts: state.dashboard.alerts,
    meters: state.dashboard.meters,
    currentMeter: state.dashboard.currentMeter,
    userInfo: state.dashboard.userInfo,
    currentMeterData: state.dashboard.currentMeterData,
  };
};

Navigation = connect(mapStateToProps, {
  saveMeterChoice,
  saveInstallationType,
  saveRedirectWizard,
  signIn,
  signOut,
  saveUser,
  saveMeters,
  saveDefaultMeter,
  saveCurrentMeter,
  saveCurrentMeterData,
  cleanMeters,
  cleanCurrentMeter,
  cleanRightNow,
  cleanAppliances,
  cleanRealtime,
  cleanBudget,
  cleanComparison,
  cleanHistory,
  cleanAlerts,
  saveRightNow,
  saveRightNowCurrentUnit,
  saveAppliances,
  saveAppliancesCurrentUnit,
  saveRealtime,
  saveRealtimeCurrentUnit,
  saveBudget,
  saveComparison,
  saveComparisonCurrentUnit,
  saveHistory,
  saveHistoryCurrentUnit,
  saveHistoryCurrentType,
  setServerError,
  saveHelp,
  saveAbout,
  saveTerms,
  saveTips,
  saveNotifications,
  savePrivacy,
  saveExplain,
  saveAlerts,
  saveLoadingRealtime,
  saveLoadingHistory,
  profileSettingsSavedSuccessfully,
  saveCC,
})(Navigation);

export default Navigation;
