import axios from "axios";
import { env } from "../env";

const migrationInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? env.migrationURL.dev
      : env.migrationURL.prod,
});

migrationInstance.interceptors.request.use((request) => {
  return request;
});

migrationInstance.interceptors.response.use((response) => {
  return response;
});

export default migrationInstance;
