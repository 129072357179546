import {
  SET_INFO,
  SAVE_CC,
  SAVE_TEMP_CC,
  SAVE_MORE_THAN_ONE_CC,
  SAVE_PREVIOUS_READING,
  SAVE_PREVIOUS_UPDATE,
  SAVE_SENSOR,
  SAVE_INSTALLATION_TYPE,
  SAVE_CC_TIMEOUT,
  SAVE_METER_CHOICE,
  SAVE_NETWORKS,
  SAVE_STRONGEST_NETWORK,
  SAVE_WIFI_PASSWORD,
  SAVE_WIFI_CREDENTIALS,
  SAVE_DEVICE_LOCATION,
  VALID_LOCATION,
  SAVE_LOCATION,
  SAVE_WIZARD_FINISHED,
  SAVE_WIZARD_PROFILE_FINISHED,
  SAVE_WIZARD_APPLIANCES_FINISHED,
  SAVE_WIZARD_SETTINGS_FINISHED,
  SAVE_REDIRECT_WIZARD,
  SAVE_MORE_THAN_ONE_CC_LIST,
  IS_MIGRATING,
  SAVE_MIGRATION_DEVICES,
  MIGRATING_DEVICE,
  IS_VALID_MIGRATION,
  SAVE_DEVICE_FOR_MIGRATION,
} from "../actions/types";

const INITIAL_STATE = {};

let wizardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_INFO:
      return { ...state, wizardInfo: action.payload };
    case SAVE_CC:
      return { ...state, cc: action.payload };
    case SAVE_TEMP_CC:
      return { ...state, tempCC: action.payload };
    case SAVE_MORE_THAN_ONE_CC:
      return { ...state, moreThanOneCc: action.payload };
    case SAVE_MORE_THAN_ONE_CC_LIST:
      return { ...state, moreThanOneCcList: action.payload };
    case SAVE_PREVIOUS_READING:
      return { ...state, previousReadingTime: action.payload };
    case SAVE_PREVIOUS_UPDATE:
      return { ...state, previousUpdateTime: action.payload };
    case SAVE_SENSOR:
      return { ...state, sensor: action.payload };
    case SAVE_INSTALLATION_TYPE:
      return { ...state, installationType: action.payload };
    case SAVE_CC_TIMEOUT:
      return { ...state, ccTimeout: action.payload };
    case SAVE_METER_CHOICE:
      return { ...state, meterChoice: action.payload };
    case SAVE_NETWORKS:
      return { ...state, wifiNetworks: action.payload };
    case SAVE_STRONGEST_NETWORK:
      return { ...state, strongestNetwork: action.payload };
    case SAVE_WIFI_PASSWORD:
      return { ...state, wifiPassword: action.payload };
    case SAVE_WIFI_CREDENTIALS:
      return { ...state, wifiCredentials: action.payload };
    case SAVE_DEVICE_LOCATION:
      return { ...state, deviceLocation: action.payload };
    case VALID_LOCATION:
      return { ...state, isValidLocation: action.payload };
    case SAVE_LOCATION:
      return { ...state, locationSavedSuccessfully: action.payload };
    case SAVE_WIZARD_FINISHED:
      return { ...state, wizardFinished: action.payload };
    case SAVE_WIZARD_PROFILE_FINISHED:
      return { ...state, wizardProfileFinished: action.payload };
    case SAVE_WIZARD_APPLIANCES_FINISHED:
      return { ...state, wizardAppliancesFinished: action.payload };
    case SAVE_WIZARD_SETTINGS_FINISHED:
      return { ...state, wizardSettingsFinished: action.payload };
    case SAVE_REDIRECT_WIZARD:
      return { ...state, redirectWizard: action.payload };
    case IS_MIGRATING:
      return { ...state, isMigrating: action.payload };
    case SAVE_MIGRATION_DEVICES:
      return { ...state, migrationDevices: action.payload };
    case MIGRATING_DEVICE:
      return { ...state, migratingDevice: action.payload };
    case IS_VALID_MIGRATION:
      return { ...state, isValidMigration: action.payload };
    case SAVE_DEVICE_FOR_MIGRATION:
      return { ...state, deviceForMigration: action.payload };
    default:
      return state;
  }
};

export default wizardReducer;
