import {
  SIGN_IN,
  SIGN_OUT,
  FORGOT_PASSWORD,
  CLEAN_FORGOT_PASSWORD,
  VALID_INVITE,
  CHANGE_PASS,
  PASSWORD_CHECK,
  VALID_PASS_RESET,
  VALID_LOGIN_CREDENTIALS,
  VALID_EMAIL_ADDRESS,
  VALID_SIGNUP,
  ACCOUNT_AVAILABLE,
  SAVE_CAPTCHA,
  SET_SERVER_ERROR,
  SAVE_USER,
  SAVE_COUNTRIES,
  SAVE_METERS,
  SAVE_DEFAULT_METER,
  SAVE_CURRENT_METER,
  SAVE_CURRENT_METER_DATA,
  SAVE_POSTAL_CODE_MAX,
  SAVE_ADMINISTRATIVE_AREAS,
  SAVE_RIGHT_NOW,
  SAVE_RIGHT_NOW_CURRENT_UNIT,
  SAVE_APPLIANCES,
  SAVE_APPLIANCES_RANGES,
  SAVE_APPLIANCES_CURRENT_UNIT,
  SAVE_APPLIANCES_SETTINGS,
  SAVE_REALTIME,
  SAVE_REALTIME_CURRENT_UNIT,
  SAVE_BUDGET,
  SAVE_COMPARISON,
  SAVE_COMPARISON_CURRENT_UNIT,
  SAVE_HISTORY,
  SAVE_HISTORY_CURRENT_UNIT,
  SAVE_HAS_METER_IMAGE,
  VALID_METER_SETTINGS,
  METER_SETTINGS_SAVED,
  VALID_PROFILE_SETTINGS,
  PROFILE_SETTINGS_SAVED,
  TOKEN_COPIED,
  SAVE_ALERTS,
  SAVE_HELP,
  SAVE_ABOUT,
  SAVE_TERMS,
  SAVE_TIPS,
  SAVE_NOTIFICATIONS,
  SAVE_PRIVACY,
  SAVE_EXPLAIN,
  SAVE_APPLIANCES_DRILLDOWN,
  LOADING_REALTIME_CHART,
  LOADING_HISTORY_CHART,
  SAVE_WINDOW_WIDTH,
  SAVE_BUDGET_SETTINGS,
  SAVE_HISTORY_CURRENT_TYPE,
  SET_INFO,
  SAVE_CC,
  SAVE_TEMP_CC,
  SAVE_PREVIOUS_READING,
  SAVE_PREVIOUS_UPDATE,
  SAVE_SENSOR,
  SAVE_INSTALLATION_TYPE,
  SAVE_CC_TIMEOUT,
  SAVE_METER_CHOICE,
  SAVE_NETWORKS,
  SAVE_STRONGEST_NETWORK,
  SAVE_WIFI_PASSWORD,
  SAVE_WIFI_CREDENTIALS,
  SAVE_DEVICE_LOCATION,
  VALID_LOCATION,
  SAVE_LOCATION,
  SAVE_WIZARD_FINISHED,
  SAVE_WIZARD_PROFILE_FINISHED,
  SAVE_WIZARD_APPLIANCES_FINISHED,
  SAVE_WIZARD_SETTINGS_FINISHED,
  SAVE_REDIRECT_WIZARD,
  VALID_CANCEL_RESET,
  VALID_DISABLE,
  SAVE_ALERT_TYPE,
  SAVE_CLIENT_TOKEN,
  TOKEN_GENERATED,
  UPDATE_FIRMWARE_SENT,
  METER_IMAGE_UPLOADED,
  PROFILE_DELETED,
  DEVICE_DELETED,
  BLOCK_ACCOUNT,
  METER_IMAGE_LOADED,
  SAVE_MORE_THAN_ONE_CC,
  SAVE_MORE_THAN_ONE_CC_LIST,
  EMAIL_TAKEN,
  SAVE_CALENDAR,
  SAVE_IS_PAGE_VISIBLE,
  SAVE_CUSTOM_RATE_PLAN,
  SAVE_SELECTED_RATES,
  SAVE_SELECTED_CHARGES,
  IS_MIGRATING,
  SAVE_MIGRATION_DEVICES,
  MIGRATING_DEVICE,
  IS_VALID_MIGRATION,
  SAVE_DEVICE_FOR_MIGRATION,
} from "./types";

export const validLoginCredentials = (data) => {
  return {
    type: VALID_LOGIN_CREDENTIALS,
    payload: data,
  };
};

export const block = (data) => {
  return {
    type: BLOCK_ACCOUNT,
    payload: data,
  };
};

export const validInvite = (data) => {
  return {
    type: VALID_INVITE,
    payload: data,
  };
};

export const validPassReset = (data) => {
  return {
    type: VALID_PASS_RESET,
    payload: data,
  };
};

export const changePassOk = (data) => {
  return {
    type: CHANGE_PASS,
    payload: data,
  };
};

export const passwordCheck = (data) => {
  return {
    type: PASSWORD_CHECK,
    payload: data,
  };
};

export const validEmailAddress = (data) => {
  return {
    type: VALID_EMAIL_ADDRESS,
    payload: data,
  };
};

export const forgotPasswordSuccess = () => {
  return {
    type: FORGOT_PASSWORD,
    payload: true,
  };
};

export const cleanForgotPasswordSuccess = () => {
  return {
    type: CLEAN_FORGOT_PASSWORD,
  };
};

export const signIn = () => {
  return {
    type: SIGN_IN,
    payload: true,
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT,
  };
};

export const validRegistration = (data) => {
  return {
    type: VALID_SIGNUP,
    payload: data,
  };
};

export const accountAvailable = (data) => {
  return {
    type: ACCOUNT_AVAILABLE,
    payload: data,
  };
};

export const saveCaptcha = (data) => {
  return {
    type: SAVE_CAPTCHA,
    payload: data,
  };
};

export const setServerError = (data) => {
  return {
    type: SET_SERVER_ERROR,
    payload: data,
  };
};

//DASHBOARD

export const saveUser = (data) => {
  return {
    type: SAVE_USER,
    payload: data,
  };
};

export const cleanUser = () => {
  return {
    type: SAVE_USER,
    payload: undefined,
  };
};

export const saveCountries = (data) => {
  return {
    type: SAVE_COUNTRIES,
    payload: data,
  };
};

export const saveAdministrativeAreas = (data) => {
  return {
    type: SAVE_ADMINISTRATIVE_AREAS,
    payload: data,
  };
};

export const cleanAdministrativeAreas = () => {
  return {
    type: SAVE_ADMINISTRATIVE_AREAS,
    payload: undefined,
  };
};

export const saveMeters = (data) => {
  return {
    type: SAVE_METERS,
    payload: data,
  };
};

export const cleanMeters = () => {
  return {
    type: SAVE_METERS,
    payload: undefined,
  };
};

export const cleanCurrentMeter = () => {
  return {
    type: SAVE_CURRENT_METER,
    payload: undefined,
  };
};

export const saveDefaultMeter = (data) => {
  return {
    type: SAVE_DEFAULT_METER,
    payload: data,
  };
};

export const saveCurrentMeter = (data) => {
  return {
    type: SAVE_CURRENT_METER,
    payload: data,
  };
};

export const saveCurrentMeterData = (data) => {
  return {
    type: SAVE_CURRENT_METER_DATA,
    payload: data,
  };
};

export const savePostalCodeMaxLength = (data) => {
  return {
    type: SAVE_POSTAL_CODE_MAX,
    payload: data,
  };
};

export const saveRightNow = (data) => {
  return {
    type: SAVE_RIGHT_NOW,
    payload: data,
  };
};

export const saveRightNowCurrentUnit = (data) => {
  return {
    type: SAVE_RIGHT_NOW_CURRENT_UNIT,
    payload: data,
  };
};

export const saveAppliances = (data) => {
  return {
    type: SAVE_APPLIANCES,
    payload: data,
  };
};

export const saveAppliancesRanges = (data) => {
  return {
    type: SAVE_APPLIANCES_RANGES,
    payload: data,
  };
};

export const saveAppliancesCurrentUnit = (data) => {
  return {
    type: SAVE_APPLIANCES_CURRENT_UNIT,
    payload: data,
  };
};

export const saveRealtime = (data) => {
  return {
    type: SAVE_REALTIME,
    payload: data,
  };
};

export const saveRealtimeCurrentUnit = (data) => {
  return {
    type: SAVE_REALTIME_CURRENT_UNIT,
    payload: data,
  };
};

export const saveBudget = (data) => {
  return {
    type: SAVE_BUDGET,
    payload: data,
  };
};

export const saveComparison = (data) => {
  return {
    type: SAVE_COMPARISON,
    payload: data,
  };
};

export const saveComparisonCurrentUnit = (data) => {
  return {
    type: SAVE_COMPARISON_CURRENT_UNIT,
    payload: data,
  };
};

export const saveHistory = (data) => {
  return {
    type: SAVE_HISTORY,
    payload: data,
  };
};

export const saveHistoryCurrentUnit = (data) => {
  return {
    type: SAVE_HISTORY_CURRENT_UNIT,
    payload: data,
  };
};

export const saveHistoryCurrentType = (data) => {
  return {
    type: SAVE_HISTORY_CURRENT_TYPE,
    payload: data,
  };
};

export const cleanRightNow = () => {
  return {
    type: SAVE_RIGHT_NOW,
    payload: undefined,
  };
};

export const cleanAppliances = () => {
  return {
    type: SAVE_APPLIANCES,
    payload: undefined,
  };
};

export const cleanRealtime = () => {
  return {
    type: SAVE_REALTIME,
    payload: undefined,
  };
};

export const cleanBudget = () => {
  return {
    type: SAVE_BUDGET,
    payload: undefined,
  };
};

export const cleanComparison = () => {
  return {
    type: SAVE_COMPARISON,
    payload: undefined,
  };
};

export const cleanHistory = () => {
  return {
    type: SAVE_HISTORY,
    payload: undefined,
  };
};

export const saveHasMeterImage = (data) => {
  return {
    type: SAVE_HAS_METER_IMAGE,
    payload: data,
  };
};

export const validMeterSettings = (data) => {
  return {
    type: VALID_METER_SETTINGS,
    payload: data,
  };
};

export const meterSettingsSavedSuccessfully = (data) => {
  return {
    type: METER_SETTINGS_SAVED,
    payload: data,
  };
};

export const meterImageUploadedSuccessfully = (data) => {
  return {
    type: METER_IMAGE_UPLOADED,
    payload: data,
  };
};

export const meterImageIsLoaded = (data) => {
  return {
    type: METER_IMAGE_LOADED,
    payload: data,
  };
};

export const validProfileSettings = (data) => {
  return {
    type: VALID_PROFILE_SETTINGS,
    payload: data,
  };
};

export const profileSettingsSavedSuccessfully = (data) => {
  return {
    type: PROFILE_SETTINGS_SAVED,
    payload: data,
  };
};

export const setTokenCopied = (data) => {
  return {
    type: TOKEN_COPIED,
    payload: data,
  };
};

export const setTokenGenerated = (data) => {
  return {
    type: TOKEN_GENERATED,
    payload: data,
  };
};

export const saveAlerts = (data) => {
  return {
    type: SAVE_ALERTS,
    payload: data,
  };
};

export const cleanAlerts = () => {
  return {
    type: SAVE_ALERTS,
    payload: undefined,
  };
};

// GET TEXTS

export const saveHelp = (data) => {
  return {
    type: SAVE_HELP,
    payload: data,
  };
};

export const saveAbout = (data) => {
  return {
    type: SAVE_ABOUT,
    payload: data,
  };
};

export const saveTerms = (data) => {
  return {
    type: SAVE_TERMS,
    payload: data,
  };
};

export const saveTips = (data) => {
  return {
    type: SAVE_TIPS,
    payload: data,
  };
};

export const saveNotifications = (data) => {
  return {
    type: SAVE_NOTIFICATIONS,
    payload: data,
  };
};

export const savePrivacy = (data) => {
  return {
    type: SAVE_PRIVACY,
    payload: data,
  };
};

export const saveExplain = (data) => {
  return {
    type: SAVE_EXPLAIN,
    payload: data,
  };
};

export const appliancesSettingsSavedSuccessfully = (data) => {
  return {
    type: SAVE_APPLIANCES_SETTINGS,
    payload: data,
  };
};

export const saveAppliancesDrilldown = (data) => {
  return {
    type: SAVE_APPLIANCES_DRILLDOWN,
    payload: data,
  };
};

export const saveLoadingRealtime = (data) => {
  return {
    type: LOADING_REALTIME_CHART,
    payload: data,
  };
};

export const saveLoadingHistory = (data) => {
  return {
    type: LOADING_HISTORY_CHART,
    payload: data,
  };
};

export const saveWindowWidth = (data) => {
  return {
    type: SAVE_WINDOW_WIDTH,
    payload: data,
  };
};

export const saveBudgetSettingsSuccessfully = (data) => {
  return {
    type: SAVE_BUDGET_SETTINGS,
    payload: data,
  };
};

// WIZARD

export const setInfo = (data) => {
  return {
    type: SET_INFO,
    payload: data,
  };
};

export const saveCC = (data) => {
  return {
    type: SAVE_CC,
    payload: data,
  };
};

export const saveTempCC = (data) => {
  return {
    type: SAVE_TEMP_CC,
    payload: data,
  };
};

export const saveMoreThanOneCC = (data) => {
  return {
    type: SAVE_MORE_THAN_ONE_CC,
    payload: data,
  };
};

export const saveMoreThanOneCCList = (data) => {
  return {
    type: SAVE_MORE_THAN_ONE_CC_LIST,
    payload: data,
  };
};

export const savePreviousReading = (data) => {
  return {
    type: SAVE_PREVIOUS_READING,
    payload: data,
  };
};

export const savePreviousUpdate = (data) => {
  return {
    type: SAVE_PREVIOUS_UPDATE,
    payload: data,
  };
};

export const saveSensor = (data) => {
  return {
    type: SAVE_SENSOR,
    payload: data,
  };
};

export const saveIsMigrating = (data) => {
  return {
    type: IS_MIGRATING,
    payload: data,
  };
};

export const saveMigrationDevices = (data) => {
  return {
    type: SAVE_MIGRATION_DEVICES,
    payload: data,
  };
};

export const migratingDevice = (data) => {
  return {
    type: MIGRATING_DEVICE,
    payload: data,
  };
};

export const saveDeviceForMigration = (data) => {
  return {
    type: SAVE_DEVICE_FOR_MIGRATION,
    payload: data,
  };
};

export const validMigration = (data) => {
  return {
    type: IS_VALID_MIGRATION,
    payload: data,
  };
};

export const saveInstallationType = (data) => {
  return {
    type: SAVE_INSTALLATION_TYPE,
    payload: data,
  };
};

export const saveCCTimeout = (data) => {
  return {
    type: SAVE_CC_TIMEOUT,
    payload: data,
  };
};

export const saveMeterChoice = (data) => {
  return {
    type: SAVE_METER_CHOICE,
    payload: data,
  };
};

export const saveNetworks = (data) => {
  return {
    type: SAVE_NETWORKS,
    payload: data,
  };
};

export const saveStrongestNetwork = (data) => {
  return {
    type: SAVE_STRONGEST_NETWORK,
    payload: data,
  };
};

export const saveWifiPassword = (data) => {
  return {
    type: SAVE_WIFI_PASSWORD,
    payload: data,
  };
};

export const saveWifiCredentials = (data) => {
  return {
    type: SAVE_WIFI_CREDENTIALS,
    payload: data,
  };
};

export const saveDeviceLocation = (data) => {
  return {
    type: SAVE_DEVICE_LOCATION,
    payload: data,
  };
};

export const validLocation = (data) => {
  return {
    type: VALID_LOCATION,
    payload: data,
  };
};

export const locationSavedSuccessfully = (data) => {
  return {
    type: SAVE_LOCATION,
    payload: data,
  };
};

export const saveWizardFinished = (data) => {
  return {
    type: SAVE_WIZARD_FINISHED,
    payload: data,
  };
};

export const saveWizardProfileFinished = (data) => {
  return {
    type: SAVE_WIZARD_PROFILE_FINISHED,
    payload: data,
  };
};

export const saveWizardAppliancesFinished = (data) => {
  return {
    type: SAVE_WIZARD_APPLIANCES_FINISHED,
    payload: data,
  };
};

export const saveWizardSettingsFinished = (data) => {
  return {
    type: SAVE_WIZARD_SETTINGS_FINISHED,
    payload: data,
  };
};

export const saveRedirectWizard = (data) => {
  return {
    type: SAVE_REDIRECT_WIZARD,
    payload: data,
  };
};

export const validCancelReset = (data) => {
  return {
    type: VALID_CANCEL_RESET,
    payload: data,
  };
};

export const validDisable = (data) => {
  return {
    type: VALID_DISABLE,
    payload: data,
  };
};

export const saveAlertType = (data) => {
  return {
    type: SAVE_ALERT_TYPE,
    payload: data,
  };
};

export const saveClientToken = (data) => {
  return {
    type: SAVE_CLIENT_TOKEN,
    payload: data,
  };
};

export const updateFirmwareSent = (data) => {
  return {
    type: UPDATE_FIRMWARE_SENT,
    payload: data,
  };
};

export const saveProfileDeleted = (data) => {
  return {
    type: PROFILE_DELETED,
    payload: data,
  };
};

export const saveDeviceDeleted = (data) => {
  return {
    type: DEVICE_DELETED,
    payload: data,
  };
};

export const emailTaken = (data) => {
  return {
    type: EMAIL_TAKEN,
    payload: data,
  };
};

export const saveCurrentCalendarValue = (data) => {
  return {
    type: SAVE_CALENDAR,
    payload: data,
  };
};

export const saveIsPageVisible = (data) => {
  return {
    type: SAVE_IS_PAGE_VISIBLE,
    payload: data,
  };
};

export const saveCustomRatePlan = (data) => {
  return {
    type: SAVE_CUSTOM_RATE_PLAN,
    payload: data,
  };
};

export const saveSelectedRates = (data) => {
  return {
    type: SAVE_SELECTED_RATES,
    payload: data,
  };
};

export const saveSelectedCharges = (data) => {
  return {
    type: SAVE_SELECTED_CHARGES,
    payload: data,
  };
};
