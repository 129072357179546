import axios from "axios";
import { env } from "../env";

const ecRestOut = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? env.restURL.dev : env.restURL.prod,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

ecRestOut.interceptors.request.use((request) => {
  return request;
});

ecRestOut.interceptors.response.use((response) => {
  return response;
});

export default ecRestOut;
