import textInstance from "../apis/textApi";

const texts = [
  "help",
  "about",
  "terms",
  "tips",
  "notifications",
  "privacy",
  "explain",
];
var languageCode = localStorage.getItem("lang");

export const getLanguage = async (props) => {
  languageCode = localStorage.getItem("lang");

  const promises = texts.map((i) =>
    textInstance
      .get(
        `/${i}_${languageCode}.${
          i === "privacy" || i === "terms" || i === "about" ? "md" : "json"
        }`
      )
      .then((res) => {
        switch (i) {
          case "help":
            props.saveHelp(res.data);
            break;
          case "about":
            props.saveAbout(res.data);
            break;
          case "terms":
            props.saveTerms(res.data);
            break;
          case "notifications":
            props.saveNotifications(res.data);
            break;
          case "tips":
            props.saveTips(res.data);
            break;
          case "privacy":
            props.savePrivacy(res.data);
            break;
          case "explain":
            props.saveExplain(res.data);
            break;
          default:
        }
      })
      .catch((error) => {})
  );

  await Promise.all(promises);
};
