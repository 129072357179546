import axios from "axios";
import { env } from "../env";

const authInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? env.authURL.dev : env.authURL.prod,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

authInstance.interceptors.request.use((request) => {
  return request;
});

authInstance.interceptors.response.use((response) => {
  return response;
});

export default authInstance;
