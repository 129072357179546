import authInstance from "../apis/ecAuth";
import restInstance from "../apis/ecRest";
import migrationInstance from "../apis/ecMigration";

import restInstanceOut from "../apis/ecRestOut";

import { env } from "../env";
import axios from "axios";

//LOGIN FLOW
export const authLogin = async (props, data) => {
  await authInstance
    .post(`/login?apiKey=${env.client}`, data)
    .then(async (res) => {
      if (res.data.success === false) {
        if (res.data.dataArray && res.data.dataArray.blocked) {
          props.block(true);
        } else {
          props.validLoginCredentials(false);
        }
      } else {
        localStorage.setItem("userId", res.data.dataArray.userId);

        props.validLoginCredentials(true);
        props.signIn();
      }
    })
    .then(() => {})
    .catch((error) => {
      props.reset();
    });
};

export const authLogout = async (props) => {
  if (localStorage.getItem("userId")) {
    await authInstance
      .get(`/logout`)
      .then(() => {
        localStorage.removeItem("userId");
        props.signOut();
      })
      .catch((error) => {});
  } else {
    return true;
  }
};

export const ResetPassword = async (props, data) => {
  await authInstance
    .post(`/pass_reset?apiKey=${env.client}`, data)
    .then((res) => {
      if (res.data.success === false) {
        props.validEmailAddress(false);
      } else {
        props.validEmailAddress(true);
        props.forgotPasswordSuccess();
        props.reset();
      }
    })
    .catch((error) => {
      props.reset();
    });
};

export const ResetPasswordCheck = async (props, data) => {
  await authInstance
    .post(`/passreset_check?apiKey=${env.client}`, data)
    .then((res) => {
      if (res.data.success === false) {
        props.validPassReset(false);
        props.reset();
      } else {
        props.validPassReset(true);
      }
    })
    .catch((error) => {
      props.reset();
    });
};

export const ResetPasswordCancel = async (props, data) => {
  await authInstance
    .post(`/passreset_cancel?apiKey=${env.client}`, data)
    .then((res) => {
      if (res.data.success === false) {
        props.validCancelReset(false);
        props.reset();
      } else {
        props.validCancelReset(true);
      }
    })
    .catch((error) => {
      props.reset();
    });
};

export const ChangePass = async (props, data) => {
  await authInstance
    .post(`/change_password?apiKey=${env.client}`, data)
    .then((res) => {
      if (res.data.success === false) {
        props.saveCaptcha(undefined);
        props.changePassOk(false);
      } else {
        props.saveCaptcha(undefined);
        props.changePassOk(true);
      }
    })
    .catch((error) => {
      props.reset();
    });
};

export const ApproveInvitation = async (props, data) => {
  await authInstance
    .post(`/approve_invitation?apiKey=${env.client}`, data)
    .then((res) => {
      if (res.data.success === false) {
        props.validInvite(false);
        props.reset();
      } else {
        props.validInvite(true);
      }
    })
    .catch((error) => {
      props.reset();
    });
};

export const DisableAlerts = async (props, data) => {
  await restInstanceOut
    .post(
      `/devices/control/disable_alerts?apiKey=${env.client}&token=${
        JSON.parse(data).token
      }`,
      data
    )
    .then((res) => {
      if (res.data.success === false) {
        props.validDisable(false);
        props.reset();
      } else if (res.data.response === "204") {
        props.validDisable(false);
        props.reset();
      } else {
        props.validDisable(true);
      }
    })
    .catch((error) => {
      props.validDisable(false);
      props.reset();
    });
};

export const RegisterAccount = async (props, data) => {
  await authInstance
    .post(`/signup?apiKey=${env.client}`, data)
    .then((res) => {
      if (res.data.success === false) {
        if (res.data.response === "400") {
          props.saveCaptcha(undefined);
          props.accountAvailable(false);
        } else {
          props.saveCaptcha(undefined);
          props.setServerError(true);
        }
        setTimeout(() => {
          props.validRegistration(false);
        }, 500);
      } else {
        props.accountAvailable(true);
        props.validRegistration(true);
        props.saveCaptcha(undefined);
        props.setServerError(undefined);
      }
    })
    .catch((error) => {
      props.reset();
    });
};

export const saveCaptchaToken = async (props, data) => {
  if (!props.savedCaptcha) {
    props.saveCaptcha(data.token);
  }
};

//MAIN FLOW

export const getUser = async (props) => {
  //Fetch user information
  await restInstance
    .get(`/users/profile`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveUser(res.data.dataArray);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const getCountries = async (props) => {
  //Fetch user information
  await restInstance
    .get(`/countries`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveCountries(res.data.dataArray);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const getAdministrativeAreas = async (countryId, allProps) => {
  switch (countryId) {
    case "CA":
      allProps.savePostalCodeMaxLength(7);
      break;
    case "US":
      allProps.savePostalCodeMaxLength(5);
      break;
    case "MX":
      allProps.savePostalCodeMaxLength(5);
      break;
    case "BG":
      allProps.savePostalCodeMaxLength(4);
      break;
    case "HN":
      allProps.savePostalCodeMaxLength(5);
      break;
    case "AU":
      allProps.savePostalCodeMaxLength(4);
      break;
    case "NZ":
      allProps.savePostalCodeMaxLength(4);
      break;
    default:
      allProps.savePostalCodeMaxLength(8);
      break;
  }

  await restInstance
    .get(`/administrative_areas?country_code=${countryId}`, allProps)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        allProps.saveAdministrativeAreas(res.data.dataArray);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const getMeters = async (props) => {
  //Fetch user information
  await restInstance
    .get(`/devices/meters`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "204") {
        props.saveMeters(res.data.dataArray);
      } else if (res.status === 200 && res.data.response === "200") {
        props.saveMeters(res.data.dataArray);
        //Get device id from URL
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const param = urlParams.get("deviceId");

        var currentMeterId = null;
        var currentMeterData = null;

        if (!isNaN(param) && param && param.length !== 0) {
          //If deviceId is correct then use search for the device
          //info inside the meters list

          res.data.dataArray.forEach((meter) => {
            meter.kh = parseFloat(meter.kh); //convert kh to number
            if (meter.default) {
              props.saveDefaultMeter(meter);
            }
            if (meter.id === parseInt(param)) {
              currentMeterId = meter.id;
              currentMeterData = meter;
            }
          });
        } else {
          //if the deviceid is incorrect use the default one
          res.data.dataArray.forEach((meter) => {
            if (meter.default) {
              props.saveDefaultMeter(meter);
              currentMeterId = meter.id;
              currentMeterData = meter;
            } else if (!meter.default && currentMeterId === null) {
              props.saveDefaultMeter(meter);
              currentMeterId = meter.id;
              currentMeterData = meter;
            }
          });
        }
        if (props.location.path === "/settings") {
          switch (currentMeterData.address.country) {
            case "CA":
              props.savePostalCodeMaxLength(7);
              break;
            case "US":
              props.savePostalCodeMaxLength(5);
              break;
            case "MX":
              props.savePostalCodeMaxLength(5);
              break;
            case "BG":
              props.savePostalCodeMaxLength(4);
              break;
            case "HN":
              props.savePostalCodeMaxLength(5);
              break;
            case "AU":
              props.savePostalCodeMaxLength(4);
              break;
            case "NZ":
              props.savePostalCodeMaxLength(4);
              break;
            default:
              props.savePostalCodeMaxLength(8);
              break;
          }
        }

        props.saveCurrentMeter(currentMeterId);
        props.saveCurrentMeterData(currentMeterData);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const setWidgetUnit = (props, data, slug) => {
  switch (slug) {
    case "rightnow":
      if (props.rightNowCurrentUnit === "d") {
        props.saveRightNowCurrentUnit("k");
        data.unit = "k";
      } else {
        props.saveRightNowCurrentUnit("d");
        data.unit = "d";
      }
      break;
    case "realtime":
      if (props.realtimeCurrentUnit === "d") {
        props.saveRealtimeCurrentUnit("k");
        data.unit = "k";
      } else {
        props.saveRealtimeCurrentUnit("d");
        data.unit = "d";
      }
      break;
    case "appliances":
      if (props.appliancesCurrentUnit === "d") {
        props.saveAppliancesCurrentUnit("k");
        data.settings.unit = "k";
      } else {
        props.saveAppliancesCurrentUnit("d");
        data.settings.unit = "d";
      }
      break;
    case "comparison":
      if (props.comparisonCurrentUnit === "d") {
        props.saveComparisonCurrentUnit("k");
        data.unit = "k";
      } else {
        props.saveComparisonCurrentUnit("d");
        data.unit = "d";
      }
      break;
    case "history":
      if (props.historyCurrentUnit === "d") {
        props.saveHistoryCurrentUnit("k");
        data.unit = "k";
      } else {
        props.saveHistoryCurrentUnit("d");
        data.unit = "d";
      }
      break;
    default:
      break;
  }

  restInstance
    .put(
      `/widgets/${slug}?did=${props.currentMeter}`,
      JSON.stringify(data),
      props
    )
    .then((res) => {})
    .catch((error) => {
      //props.reset();
    });
};

export const getRightNow = async (id, props) => {
  await restInstance
    .get(`/widgets/rightnow?did=${id}`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveRightNow(res.data.dataArray);
      }
      //props.saveRightNowCurrentUnit(res.data.dataArray.settings.unit);
    })
    .catch((error) => {
      //props.reset();
    });
};

export const getAppliances = async (id, props) => {
  await restInstance
    .get(`/widgets/appliances?did=${id}`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveAppliances(res.data.dataArray);
        props.saveAppliancesCurrentUnit(res.data.dataArray.settings.unit);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const getAppliancesRanges = async (props) => {
  await restInstance
    .get(`/widgets/appliances/ranges`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveAppliancesRanges(res.data.dataArray);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const getAppliancesDetails = async (id, startDate, props) => {
  await restInstance
    .get(`/widgets/appliances?did=${id}&startdate=${startDate}`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveAppliances(res.data.dataArray);
        props.saveAppliancesCurrentUnit(res.data.dataArray.settings.unit);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const getAppliancesDrilldown = async (id, applianceId, props) => {
  await restInstance
    .get(`/widgets/appliances?did=${id}&appliance_id=${applianceId}`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveAppliancesDrilldown(res.data.dataArray.data);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const saveAppliancesSettings = async (props, data) => {
  await restInstance
    .put(`/widgets/appliances?did=${props.currentMeterData.id}`, data, props)
    .then((res) => {
      if (res.data.success === false) {
        if (res.data.response !== "400") {
          props.setServerError(true);
        }
        setTimeout(() => {
          props.appliancesSettingsSavedSuccessfully(false);
        }, 500);
      } else {
        if (res.status === 200 && res.data.response === "200") {
          props.appliancesSettingsSavedSuccessfully(true);
          props.setServerError(undefined);
          props.getAppliances(props.currentMeterData.id, props);
        }
      }
    })
    .catch((error) => {
      props.setServerError(true);
      //props.reset();
    });
};

export const getRealtime = async (id, props) => {
  await props.saveLoadingRealtime(true);

  await restInstance
    .get(`/widgets/realtime?did=${id}`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveRealtime(res.data.dataArray);
        //props.saveRealtimeCurrentUnit(res.data.dataArray.settings.unit);
        props.saveLoadingRealtime(false);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const getRealtimeDetails = async (id, endDate, props) => {
  props.saveLoadingRealtime(true);

  await restInstance
    .get(`/widgets/realtime?did=${id}&enddate=${endDate}`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveRealtime(res.data.dataArray);
        //props.saveRealtimeCurrentUnit(res.data.dataArray.settings.unit);
        props.saveLoadingRealtime(false);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const getBudget = async (id, props) => {
  await restInstance
    .get(`/widgets/gasgauge?did=${id}`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveBudget(res.data.dataArray);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const saveBudgetSettings = async (props, data) => {
  await restInstance
    .put(`/widgets/gasgauge?did=${props.currentMeter}`, data, props)
    .then((res) => {
      if (res.data.success === false) {
        if (res.data.response !== "400") {
          props.setServerError(true);
        }
        setTimeout(() => {
          props.saveBudgetSettingsSuccessfully(false);
        }, 500);
      } else {
        if (res.status === 200 && res.data.response === "200") {
          props.saveBudgetSettingsSuccessfully(true);
          props.setServerError(undefined);
          return true;
        }
      }
    })
    .catch((error) => {
      props.setServerError(true);
      //props.reset();
    });
};

export const getComparison = async (id, props) => {
  await restInstance
    .get(`/widgets/comparison?did=${id}`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveComparison(res.data.dataArray);
        props.saveComparisonCurrentUnit(res.data.dataArray.settings.unit);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const getHistory = async (id, props) => {
  await props.saveLoadingHistory(true);
  await restInstance
    .get(`/widgets/history?did=${id}`, props)
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveHistory(res.data.dataArray);
        props.saveHistoryCurrentUnit(res.data.dataArray.settings.unit);
        props.saveLoadingHistory(false);
        props.saveHistoryCurrentType(res.data.dataArray.settings.type);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const getHistoryDetails = async (id, startdate, type, props) => {
  await props.saveLoadingHistory(true);

  await restInstance
    .get(
      `/widgets/history?did=${id}&startdate=${startdate}&type=${type}`,
      props
    )
    .then((res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveHistory(res.data.dataArray);
        props.saveLoadingHistory(false);
        props.saveHistoryCurrentType(type);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const setHistoryType = async (data, props) => {
  await restInstance
    .put(`/widgets/history?did=${props.currentMeter}`, data, props)
    .then((res) => {})
    .catch((error) => {
      //props.reset();
    });
};

export const saveMeterSettings = async (props, data) => {
  await restInstance
    .put(`/devices/meters?did=${props.currentMeter}`, data, props)
    .then((res) => {
      if (res.data.success === false) {
        if (res.data.response !== "400") {
          props.setServerError(true);
          props.validMeterSettings(false);
        }
        setTimeout(() => {
          props.meterSettingsSavedSuccessfully(false);
        }, 500);
      } else {
        if (res.status === 200 && res.data.response === "200") {
          props.meterSettingsSavedSuccessfully(true);
          props.setServerError(undefined);
          props.validMeterSettings(undefined);
        }
      }
    })
    .catch((error) => {
      props.setServerError(true);
      //props.reset();
    });
};

export const saveProfileSettings = async (props, data) => {
  await restInstance
    .put(`/users/profile`, data, props)
    .then((res) => {
      if (res.data.success === false) {
        if (res.data.response !== "400") {
          props.setServerError(true);
          props.validProfileSettings(false);
        } else {
          setTimeout(() => {
            props.emailTaken(true);
          }, 500);
        }
        setTimeout(() => {
          props.profileSettingsSavedSuccessfully(false);
        }, 500);
      } else {
        if (res.status === 200 && res.data.response === "200") {
          props.emailTaken(false);
          props.profileSettingsSavedSuccessfully(true);
          props.setServerError(undefined);
          props.validProfileSettings(undefined);
        }
      }
    })
    .catch((error) => {
      props.setServerError(true);
      //props.reset();
    });
};

export const saveProfileLanguage = async (props, data) => {
  await restInstance
    .put(`/users/language`, data, props)
    .then((res) => {})
    .catch((error) => {
      //props.reset();
    });
};

export const getAlerts = async (currentMeter, props) => {
  var currentId = currentMeter;

  await restInstance
    .get(`/devices/alerts?did=${currentId}`, props)
    .then((res) => {
      if (res.data.success === false) {
        if (res.data.response !== "400") {
          props.setServerError(true);
        }
      } else {
        props.saveAlerts(res.data.dataArray);
      }
    })
    .catch((error) => {
      //props.reset();
    });
};

export const deleteAlert = async (props, deviceId, data) => {
  if (deviceId) {
    await restInstance
      .post(
        `/devices/control/delete_alerts?did=${deviceId}`,
        { event_id: data },
        props
      )
      .then((res) => {
        if (res.data.success === false) {
        } else {
        }
      })
      .catch((error) => {
        //props.reset();
      });
  }
};

export const generateAccessToken = async (props) => {
  await restInstance
    .post(`/users/token`, null, props)
    .then(async (res) => {
      if (res.data.success === false) {
        props.saveClientToken(null);
      } else {
        let accessToken = res.data.dataArray.client_token;
        props.saveClientToken(accessToken);
        props.change("token", accessToken);
      }
    })
    .then(() => {})
    .catch((error) => {
      //props.reset();
    });
};

export const sendFirmwareUpdate = async (props, deviceId, data) => {
  if (deviceId) {
    await restInstance
      .post(`/devices/control/update_firmware?did=${deviceId}`, data, props)
      .then((res) => {
        if (res.data.success === false) {
          props.updateFirmwareSent({
            status: "error",
            deviceId: deviceId,
          });
          return res;
        } else {
          if (res.status === 200 && res.data.response === "200") {
            props.updateFirmwareSent({
              status: "pending",
              deviceId: deviceId,
            });

            return res;
          }
        }
      })
      .catch((error) => {
        //props.reset();
      });
  }
};

export const uploadImageToS3 = (props, deviceId, data, hasMeterImage) => {
  return new Promise((resolve, reject) => {
    if (deviceId) {
      let uploadObj = {
        location: null,
      };

      restInstance
        .post(`/devices/control/upload_image?did=${deviceId}`, data, props)
        .then((res) => {
          props.meterImageIsLoaded(false);

          if (res.data.success === false) {
            if (hasMeterImage) {
              document.getElementById("meterImage").src =
                uploadObj.location + `?${Math.floor(Math.random() * 999999)}`;
              props.saveHasMeterImage(true);
            }

            resolve(uploadObj);

            return true;
          } else if (res.data.response === "204") {
            resolve(uploadObj);
            return true;
          } else {
            if (res.status === 200 && res.data.response === "200") {
              uploadObj = {
                location: res.data.dataArray.Location,
              };
              if (hasMeterImage) {
                document.getElementById("meterImage").src = "";
                document.getElementById("meterImage").src =
                  uploadObj.location + `?${Math.floor(Math.random() * 999999)}`;
              }
              props.saveHasMeterImage(true);
              props.meterImageUploadedSuccessfully("uploaded");
              resolve(uploadObj);
              return true;
            }
          }
        })
        .catch((error) => {
          //props.reset();
        });
    }
  });
};

export const deleteProfile = async (props) => {
  await restInstance
    .delete(`/users/profile`, props)
    .then(async (res) => {
      if (res.data.success === false) {
        props.saveProfileDeleted(true);
      } else {
        props.saveProfileDeleted(true);
      }
    })
    .then(() => {})
    .catch((error) => {
      //props.reset();
    });
};

export const deleteDevice = async (props, deviceId, readings) => {
  await restInstance
    .delete(
      `/devices/control/delete?did=${deviceId}&readings=${readings}`,
      props
    )
    .then(async (res) => {
      if (res.status === 200 && res.data.response === "200") {
        props.saveDeviceDeleted(true);
        window.location.href = "/dashboard";
      }
    })
    .then(() => {})
    .catch((error) => {
      //props.reset();
    });
};

export const replaceDevice = async (props, data) => {
  await restInstance
    .post(`/devices/control/replace`, data, props)
    .then(async (res) => {
      if (
        res.data.success === true &&
        res.status === 200 &&
        res.data.response === "200"
      ) {
        window.location.href = `/dashboard?deviceId=${res.data.dataArray.deviceId}`;
      }
    })
    .then(() => {})
    .catch((error) => {
      //props.reset();
    });
};

//
// MIGRATION STUFF
//
export const startMigrating = (props, data) => {
  return new Promise((resolve, reject) => {
    restInstance
      .post(`/migrate`, data, props)
      .then(async (res) => {
        resolve(res.data.response);
        return true;
      })
      .then(() => {})
      .catch((error) => {
        props.reset();
        resolve(error);
        return true;
      });
  });
};

export const getUserInfo = (props, data) => {
  return new Promise((resolve, reject) => {
    //Fetch user information
    migrationInstance
      .get(
        `/getUserInfo?apiKey=C77jNquRFTxZx74Usbgk8iAqVEZBAXav&email=${data.email}`,
        data
      )
      .then((res) => {
        if (
          res.data.response === "200" &&
          res.data.dataArray[0]?.country &&
          res.data.dataArray[0]?.zipcode &&
          res.data.dataArray[0]?.user_tz
        ) {
          if (res.data.dataArray[0]?.country === "Canada") {
            res.data.dataArray[0].country = "CA";
          }
          const obj = {
            country: res.data.dataArray[0]?.country,
            zipcode: res.data.dataArray[0]?.zipcode,
            user_tz: res.data.dataArray[0]?.user_tz,
            status: res.data.response,
          };
          resolve(obj);
          return true;
        }
      })
      .catch((error) => {
        props.reset();
        resolve(error);
        return true;
      });
  });
};

export const getClientIp = async (props) => {
  return new Promise((resolve, reject) => {
    axios
      .get("https://energycloud3.bluelineinnovations.com/myipaddr.php")
      .then((res) => {
        resolve(res.data);
        return true;
      })
      .catch((error) => {
        props.reset();
        resolve(error);
        return true;
      });
  });
};

export const getDeviceInfo = (props, data) => {
  //Fetch user information
  return new Promise((resolve, reject) => {
    migrationInstance
      .get(
        `/getDeviceInfo?apiKey=C77jNquRFTxZx74Usbgk8iAqVEZBAXav&ipaddr=${data.ipaddr}`,
        data
      )
      .then((res) => {
        if (res.data.response === "200") {
          res.data.dataArray.forEach((obj, element) => {
            // if rate plan id is 0 then we save it to 9 (default plan).
            // 0 in the ec3 app means we have a custom rate plan (which is not the case in ec2)

            if (obj?.rate_plan_id === "0") {
              res.data.dataArray[element].rate_plan_id = 9;
            }
          });

          props.saveMigrationDevices(res.data.dataArray);
          resolve(res.data.dataArray);
          return true;
        } else {
          resolve(false);
          return false;
        }
      })
      .catch((error) => {
        props.reset();
        resolve(error);
        return true;
      });
  });
};
