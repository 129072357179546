import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./styles/App.css";
import styles from "./styles";
import LoadingScreen from "./screens/LoadingScreen";
import Navigation from "./ui/Navigation";

const Login = React.lazy(() => import("./screens/Login"));
const ForgotPassword = React.lazy(() => import("./screens/ForgotPassword"));
const Signup = React.lazy(() => import("./screens/Signup"));
const CancelReset = React.lazy(() => import("./screens/CancelReset"));
const Validate = React.lazy(() => import("./screens/Validate"));
const Disable = React.lazy(() => import("./screens/Disable"));
const PassReset = React.lazy(() => import("./screens/ResetPassword"));
const Dashboard = React.lazy(() => import("./screens/Dashboard"));
const Settings = React.lazy(() => import("./screens/Settings"));
const Profile = React.lazy(() => import("./screens/Profile"));
const Messages = React.lazy(() => import("./screens/Messages"));
const Help = React.lazy(() => import("./screens/Help"));
const Setup = React.lazy(() => import("./screens/Setup"));
const Explain = React.lazy(() => import("./screens/Explain"));
const Logout = React.lazy(() => import("./screens/Logout"));
const Footer = React.lazy(() => import("./ui/Footer"));
const PageNotFound = React.lazy(() => import("./screens/PageNotFound"));

const App = () => {
  return (
    <div>
      <BrowserRouter path={"/"} basename={"/"}>
        <div style={styles.container}>
          <div style={{ display: "flex", flex: 1, width: "100%" }}>
            <Navigation />

            <div
              style={styles.dashboardContainer}
              className="dashboardContainer"
            >
              <div style={styles.routerStyle}>
                <Suspense fallback={<LoadingScreen />}>
                  {/* UNAUTHORIZED ROUTES */}
                  <Switch>
                    <Route path="/" exact component={Login} />
                    <Route path="/login" exact component={Login} />
                    <Route
                      path="/forgotPassword"
                      exact
                      component={ForgotPassword}
                    />
                    <Route path="/signup" exact component={Signup} />
                    <Route path="/validate" exact component={Validate} />
                    <Route path="/resetPassword" exact component={PassReset} />
                    <Route path="/cancelReset" exact component={CancelReset} />
                    <Route path="/explain" exact component={Explain} />
                    <Route path="/disable" exact component={Disable} />
                    {/* AUTHORIZED ROUTES */}
                    <Route path="/dashboard" exact component={Dashboard} />
                    <Route path="/dashboard/:id" exact component={Dashboard} />
                    <Route path="/settings" exact component={Settings} />
                    <Route path="/settings/:id" exact component={Settings} />
                    <Route path="/profile" exact component={Profile} />
                    <Route path="/messages" exact component={Messages} />
                    <Route path="/help" exact component={Help} />
                    <Route path="/setup" exact component={Setup} />
                    <Route path="/logout" exact component={Logout} />
                    <Route path="/*" component={PageNotFound} />
                  </Switch>
                </Suspense>
              </div>
            </div>
          </div>
          <Suspense fallback={<></>}>
            <Footer />
          </Suspense>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
