import axios from "axios";
import { env } from "../env";
import resolveOriginalRequest from "./resolveOriginalRequest.js";

const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? env.restURL.dev : env.restURL.prod,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

let isRefreshing = false;
let requestsQueue = [];

instance.interceptors.request.use((request) => {
  return request;
});

instance.interceptors.response.use(
  async (response) => {
    let originalConfig = response.config;
    //when trying to access internal page and there is no cookie
    //the api will return unauthorized - logout the user.
    if (response.data.message === "Unauthorized") {
      logoutUser();
    }

    if (response?.data?.response === "401") {
      //adding the unresolved request in a queue
      requestsQueue.push(originalConfig);

      let respRefresh = await handleRefresh();

      if (respRefresh?.dataArray?.disabled === 1) {
        logoutUser();
      }

      if (respRefresh?.response === "401") {
        logoutUser();
      }

      //grab all of the failed requests and resolve them
      let n = 0;
      requestsQueue.forEach((req) => {
        originalConfig = {
          ...req,
        };
        resolveOriginalRequest(originalConfig);

        n++;
        if (n === requestsQueue.length) {
          isRefreshing = false;
          requestsQueue = [];
        }
      });
    }
    return response;
  },
  async (error) => {
    // logoutUser();
    return Promise.reject(error);
  }
);

const logoutUser = async () => {
  return new Promise((resolve, reject) => {
    localStorage.removeItem("userId");

    fetch(
      `${
        process.env.NODE_ENV === "development"
          ? env.authURL.dev
          : env.authURL.prod
      }/logout`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    )
      .then(() => {
        window.location.replace("/login");
        resolve();
      })
      .catch(() => {
        window.location.replace("/login");
        resolve();
      });
  });
};

const handleRefresh = async () => {
  return new Promise((resolve, reject) => {
    if (!isRefreshing) {
      isRefreshing = true;

      fetch(
        `${
          process.env.NODE_ENV === "development"
            ? env.authURL.dev
            : env.authURL.prod
        }/refresh`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        });
    } else {
      reject();
    }
  });
};

export default instance;
