import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import authReducer from "./authReducer";
import dashboardReducer from "./dashboardReducer";
import textReducer from "./textReducer";
import WizardReducer from "./wizardReducer";
import ratePlanReducer from "./ratePlanReducer";

export default combineReducers({
  auth: authReducer,
  form: formReducer,
  dashboard: dashboardReducer,
  text: textReducer,
  wizard: WizardReducer,
  ratePlanReducer: ratePlanReducer,
});
