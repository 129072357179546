import React from "react";
import styles from "../styles";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const LoadingScreen = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const pathName = location.pathname;

  return (
    <div>
      {pathName === "/dashboard" ||
      pathName === "/settings" ||
      pathName === "/profile" ||
      pathName === "/messages" ||
      pathName === "/help" ||
      pathName === "/setup" ? (
        <div style={styles.container}>
          <div style={styles.page}>
            <div style={styles.mainContent} id="mainContent loadingDescription">
              <div className="loader"></div>
              <div style={styles.loadingText}>
                <span style={styles.loadingDescription}>
                  {t("settings.loading")}...
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className={"loader"}></div>
          <div style={styles.loadingText}>
            <span style={styles.loadingDescription}>
              {t("settings.loading")}...
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;
