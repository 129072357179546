import axios from "axios";
import { env } from "../env";

const textInstance = axios.create({
  baseURL: env.textURL,
});

textInstance.interceptors.request.use((request) => {
  return request;
});

textInstance.interceptors.response.use((response) => {
  return response;
});

export default textInstance;
