export const env = {
  client: "gA2fgRjynxYJdze2WisXu2y41Njm4vYb",
  clientPublic: "Y51pJ66eRj8ltNrHdE0REM0H4Vl5h3Un",
  recaptchaKey: "6LdioMAUAAAAAPn6YHJTpnjEoJPBmu4vSH7Cgqlq",
  recaptchaURL: "https://www.google.com/recaptcha/api",
  textURL: "https://energycloud3.bluelineinnovations.com/texts",
  authURL: {
    dev: "https://ec3api-test.bluelineinnovations.com/v1/auth",
    prod: "https://ec3api.bluelineinnovations.com/v1/auth",
  },
  restURL: {
    dev: "https://ec3api-test.bluelineinnovations.com/v1",
    prod: "https://ec3api.bluelineinnovations.com/v1",
  },
  wizardURL: {
    dev: "https://ec3api-test.bluelineinnovations.com/v1/installation",
    prod: "https://ec3api.bluelineinnovations.com/v1/installation",
  },
  meterImagesURL: {
    dev: "https://customers-meters-img.s3.us-west-2.amazonaws.com/",
    prod: "https://customers-meters-img.s3.us-west-2.amazonaws.com/",
  },
  migrationURL: {
    dev: "https://ec2migration-api.bluelineinnovations.com", //http://migration-api.bluelineinnovations.local
    prod: "https://ec2migration-api.bluelineinnovations.com", //https://migration-api.bluelineinnovations.com
  },
};
